<template>
	<div>
		<div class="row mt-3">
			<div class="col-6">
				<div class="form-group">
					<label for="">{{ $t('monte.reservation.echeance') }}</label>
					<e-datepicker v-model="delivery_date"></e-datepicker>
				</div>
				<div class="form-group">
					<label for="">{{ $t('monte.reservation.centre_stockage') }}</label>
					<e-select
						v-model="expediteur"
						id="expediteur"
						track-by="tiers_id"
						label="tiers_rs"
						:placeholder="$t('monte.reservation.choisir_centre_stockage')"
						:selectedLabel="$t('global.selected_label')"
						:options="available_centre_stockage"
						:allow-empty="true"
						:show-labels="false"
					></e-select>
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label for="">{{ $t('monte.reservation.date_send_order') }}</label>
					<e-datepicker v-model="sendorder_date"></e-datepicker>
				</div>
				<div>
					<label></label>
					<b-button variant="primary" block @click="searchBon">{{ $t("global.rechercher") }}</b-button>
				</div>
			</div>
		</div>
		<CustomTable
			ref="monte_resa"
			id_table="monte_resa_bon"
			primaryKey="id"
			:items="filtred_items"
			:busy.sync="table_busy"
			:hrefsRoutes="config_table_hrefs"
			:base-filters="tableFilters"
		/>	
	</div>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
	import MonteReservationOrder from "@/mixins/MonteReservationOrder.js"

	export default {
		name: "Bon",
		props: {
			items: { type: Array, default: () => [] },
			table_busy: { type: Boolean, default: false }
		},
		mixins: [Navigation, MonteReservationOrder],
		data () {
			return {
				expediteur: null,
				delivery_date: null,
				sendorder_date: null,
				filtred_items: [],
				available_centre_stockage: [],
				events_tab: {
					"MonteReservation::RefreshBon": () => { this.$refs.monte_resa.refreshTable() },
				},
				config_table_hrefs: {
					'expediteur.tiers_rs': {
						routeUniqueName: 'tierExpeFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'expediteur.tiers_id'
						}
					},
					'destinataire.tiers_rs': {
						routeUniqueName: 'tierDestFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'destinataire.tiers_id'
						}
					},
					'stallion.horse_nom': {
						routeUniqueName: 'stallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'stallion.horse_id'
						}
					},
					'mare.horse_nom': {
						routeUniqueName: 'mareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'stallion.horse_id'
						}
					},
					'contract.contract_num': {
						routeUniqueName: 'contractFiche',
						routeName: 'ContractFormv2',
						params: {
							contract_id: 'contract.contract_id'
						}
					},
				}
			}
        },
        created() {
        	this.init_component()
        },
        methods: {
        	async init_component() {
        		this.available_centre_stockage = await this.loadCentreStockage()
        	},
        	unselectAll() {
        		this.$refs.monte_resa.unselectAll()
        	},
        	searchBon() {
        		if(!this.items) {
        			return false
        		}
        		let items = this.items
        		if(this.expediteur) {
        			items = items.filter(item => item.expediteur && item.expediteur.tiers_id == this.expediteur.tiers_id)
        		}
        		if(this.delivery_date) {
        			items = items.filter(item => item.delivery_date == this.delivery_date.toDateInputValue())
        		}
        		if(this.sendorder_date) {
        			items = items.filter(item => item.order && item.order.sendorder_date == this.sendorder_date.toDateInputValue())
        		}
        		this.filtred_items = items
        	},
        },
        computed: {
			tableFilters() {
				return {
					order_status: {
						column: 'status_code',
						operator: 'isBetween',
						value: [1, 3]
					}
				}
			}
		},
		watch: {
			'items': {
				deep: true,
				handler(val) {
					this.searchBon()
				}
			}
		},
		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			SearchTiers : () => import('@/components/Contract/SearchTiers')
        }
	}
</script>
